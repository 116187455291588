import { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Input, Menu, Modal, Image, message } from "antd";
import type { ConfigProviderProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import eth_logo from "../assets/img/eth_logo.png";
import bnb_logo from "../assets/img/bnb-logo.png";
import linea_logo from "../assets/img/linea-logo-mainnet.png";
import AccountAction from "./account.action";

type SizeType = ConfigProviderProps["componentSize"];

interface HeadersProps {
  account: string;
}

const networks = [
  { id: 1, name: "Ethereum Mainnet", logo: eth_logo },
  { id: 2, name: "Linea Mainnet", logo: linea_logo },
  { id: 3, name: "BNB", logo: bnb_logo },
];

function Headers({ account }: HeadersProps) {


  const [messageApi, showMessage] = message.useMessage();
  const [size, setSize] = useState<SizeType>("large");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredNetworks, setFilteredNetworks] = useState(networks);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    const filtered = networks.filter((network) =>
      network.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredNetworks(filtered);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClick = (network:any) => {
    messageApi.open({
      type: 'success',
      content: `${network.name} coming soon...`,
    });
    setIsModalOpen(false);
  };


  return (
    <>
    {showMessage}
      <ul
        style={{
          width:'90%',
          listStyleType: "none",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <li>
          <Button
            style={{ backgroundColor: "#f3f4f6" }}
            shape="round"
            size={size}
            onClick={showModal}
          >
            Kyber
            <span style={{ marginLeft: "10px" }}>
              <DownOutlined />
            </span>
          </Button>
        </li>
        <li>
          <div style={{ display: "flex", alignItems: "center", marginRight:"30px" }}>
            <div
              style={{
                borderRadius: "50px",
                overflow: "hidden",
                padding: "0px",
                margin: "0px",
                width: "24px",
                height: "24px",
                display: "inline-block",
                background: "rgb(200, 20, 116)",
                marginRight: "5px",
              }}
            >
              <svg x="0" y="0" width="24" height="24">
                <rect
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  transform="translate(-5.685411221683125 -0.8808839200221418) rotate(346.7 12 12)"
                  fill="#018E74"
                ></rect>
                <rect
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  transform="translate(-1.0214631916780919 -12.980198201595536) rotate(295.3 12 12)"
                  fill="#F90901"
                ></rect>
                <rect
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  transform="translate(-16.184369742620913 -8.810091222351083) rotate(385.4 12 12)"
                  fill="#238FE1"
                ></rect>
              </svg>
            </div>
            Account 1
          </div>
        </li>
        <li className='setting-dropdown'>
          
          {/* list will be show here  */}
          <AccountAction />
        </li>
      </ul>

      <Modal
        title="Select a network"
        className="antd-headerModel"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Input
          placeholder="Search networks"
          prefix={<SearchOutlined />}
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
          onPressEnter={() => handleSearch(searchValue)}
        />
        <Menu mode="vertical" className="network-list-header">
          {filteredNetworks.map((network) => (
            <Menu.Item style={{ padding: "0" }} key={network.id} onClick={() => handleClick(network)}>
              <Image
                className="network-logo-header"
                src={network.logo}
                width={30}
              />
              <span className="network-name-header">{network.name}</span>
            </Menu.Item>
          ))}
        </Menu>
        <Button className="add-network-btn">Add network</Button>
      </Modal>
    </>
  );
}

export default Headers;
