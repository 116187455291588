import React, { useEffect, useState, useRef } from "react";
import {
  ArrowUpOutlined,
  CopyOutlined,
  ForkOutlined,
  LineChartOutlined,
  PercentageOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { sendToken } from "../../utils/TransactionUtils";
import { goerli } from "../../models/Chain";
import { Account } from "../../models/Account";
import AccountTransactions from "./AccountTransactions";
import { ethers } from "ethers";
import { toFixedIfNecessary } from "../../utils/AccountUtils";
import "./Account.css";
import {message,Tooltip, Modal } from "antd";
import Body from "../../components/Body";
import NFTs from "../../components/NFTs";
import Tokens from "../../components/Tokens";

interface AccountDetailProps {
  account: Account;
}

const AccountDetail: React.FC<AccountDetailProps> = ({ account }) => {
  const addressRef = useRef(null);

  const [messageApi, showMessage] = message.useMessage();
  const [networkResponse, setNetworkResponse] = useState<{
    status: null | "pending" | "complete" | "error";
    message: string | React.ReactElement;
  }>({
    status: null,
    message: "",
  });
  const [destinationAddress, setDestinationAddress] = useState("");
  const [amount, setAmount] = useState(0);
  const [balance, setBalance] = useState(account.balance);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const truncatedAddress = `${account.address.slice(
    0,
    6
  )}...${account.address.slice(-4)}`;

  const handleCopyAddress = () => {
    if (addressRef.current) {
      navigator.clipboard.writeText(account.address);
    }
  };


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  


  useEffect(() => {
    const fetchData = async () => {
      const provider = new ethers.providers.JsonRpcProvider(goerli.rpcUrl);
      let accountBalance = await provider.getBalance(account.address);
      setBalance(
        String(toFixedIfNecessary(ethers.utils.formatEther(accountBalance)))
      );
    };
    fetchData();
  }, [account.address]);

  function handleDestinationAddressChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setDestinationAddress(event.target.value);
  }

  function handleAmountChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAmount(Number.parseFloat(event.target.value));
  }


  async function transfer() {

    setNetworkResponse({
      status: "pending",
      message: "",
    });

    setIsModalOpen(false);

    messageApi.open({
      type: 'success',
      content: 'Transaction Complete',
    });

    try {
      const { receipt } = await sendToken(
        amount,
        account.address,
        destinationAddress,
        account.privateKey
      );

      if (receipt.status === 1) {
        // Set the network response status to "complete" and the message to the transaction hash
        setNetworkResponse({
          status: "complete",
          message: (
            <p>
              Transfer complete!{" "}
              <a
                href={`${goerli.blockExplorerUrl}/tx/${receipt.transactionHash}`}
                target="_blank"
                rel="noreferrer"
              >
                View transaction
              </a>
            </p>
          ),
        });
        return receipt;
      } else {
        // Transaction failed
        console.log(`Failed to send ${receipt}`);
        // Set the network response status to "error" and the message to the receipt
        setNetworkResponse({
          status: "error",
          message: JSON.stringify(receipt),
        });
        return { receipt };
      }
    } catch (error: any) {
      // An error occurred while sending the transaction
      console.error({ error });
      // Set the network response status to "error" and the message to the error
      setNetworkResponse({
        status: "error",
        message: error.reason || JSON.stringify(error),
      });
    }
  }

  return (
    <div className="AccountDetail container">
       {showMessage}
      <ul
        style={{
          marginTop: "20px",
          marginBottom: "25px",
        }}
      >
        <li>
          <Tooltip title="Copied" trigger="click">
            <span
              onClick={handleCopyAddress}
              ref={addressRef}
              style={{
                cursor: "pointer",
                background: "#d1d1d1",
                padding: "11px 30px",
                borderRadius: "20px",
                color: "rgb(59 51 19)",
              }}
            >
              {truncatedAddress}
              <CopyOutlined style={{ marginLeft: "10px" }} />
            </span>
          </Tooltip>
        </li>
      </ul>
      <p className="wallet-bal">{balance} KYB</p>

      <ul className="transaction-icons">
        <li>
          <div className="pointer">
            <PercentageOutlined className="tr-icon" />
            <p style={{ display: "block" }}>Buy&Sell</p>
          </div>
        </li>
        <li>
          <div className="pointer" onClick={showModal}>
            <ArrowUpOutlined className="tr-icon deg-45" />
            <p style={{ display: "block" }}>Send</p>
          </div>
        </li>
        <li>
          <div className="pointer">
            <SwapOutlined className="tr-icon" />
            <p style={{ display: "block" }}>Swap</p>
          </div>
        </li>
        <li>
          <div className="pointer">
            <ForkOutlined className="tr-icon" />
            <p style={{ display: "block" }}>Bridge</p>
          </div>
        </li>
        <li>
          <div className="pointer">
            <LineChartOutlined className="tr-icon" />
            <p style={{ display: "block" }}>Portfolio</p>
          </div>
        </li>
      </ul>
      {/* Account body  */}
      <Body
        tab1={<Tokens />}
        tab2={<NFTs />}
        tab3={<AccountTransactions account={account} />}
      />

      <Modal
        title="Send"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="form-group">
          <label>Destination Address:</label>
          <input
            className="form-control"
            type="text"
            value={destinationAddress}
            onChange={handleDestinationAddressChange}
          />
        </div>

        <div className="form-group">
          <label>Amount:</label>
          <input
            className="form-control"
            type="number"
            value={amount}
            onChange={handleAmountChange}
          />
        </div>

        <button
          className="btn btn-primary"
          type="button"
          onClick={transfer}
          disabled={!amount || networkResponse.status === "pending"}
        >
          Send {amount} KYB
        </button>
        {networkResponse.status && (
          <>
            {networkResponse.status === "pending" && (
              <p>Transfer is pending...</p>
            )}
            {networkResponse.status === "complete" && (
              <p>{networkResponse.message}</p>
            )}
            {networkResponse.status === "error" && (
              <p>
                Error occurred while transferring tokens:{" "}
                {networkResponse.message}
              </p>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default AccountDetail;
