import React from "react";
import { Form, Input, message } from "antd";
import logo from "../assets/img/sc-offical.png";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated } from "../reducers/accountReducer";
import { RootState } from "../store";

const LockScreen: React.FC = () => {
  const [messageApi, showMessage] = message.useMessage();
  const dispatch = useDispatch();
  const savedPassword = useSelector(
    (state: RootState) => state.account.password
  );

  const onFinish = (values: any) => {
    if (savedPassword && values.password === savedPassword) {
      dispatch(setIsAuthenticated("false"))
    } else {
      messageApi.open({
        type: "error",
        content: "Wrong Password !!!",
      });

    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
       {showMessage}
      <ul className="lock-header">
        <li>
          <img src={logo} alt="" />
        </li>
        <li>
          <span className="heading"> KYBER </span>
        </li>
      </ul>
      <div className="lockscreen-body">
        <img src={logo} alt="logo" />
        <h1>Welcome back!</h1>
        <p>The decentralized web awaits</p>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="password"
            className="lock-input-password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password  placeholder="input your password" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <button>unlock</button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LockScreen;
