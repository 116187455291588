import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  message,
  Dropdown,
  Input,
  Menu,
  Modal,
  notification,
  Form,
  Button,
} from "antd";
import {
  ImportOutlined,
  LockOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { RootState } from "../store";

import {
  setRecoveryPhrase,
  setAccount,
  setEncryptedRecoveryPhrase,
  setIsAuthenticated,
  setPassword,
} from "../reducers/accountReducer";
import { generateAccount } from "../utils/AccountUtils";
import { encrypt } from "../utils/cryptoUtils";

const AccountAction: React.FC = () => {
  const dispatch = useDispatch();
  const passwrod = useSelector((state: RootState) => state.account.password);
  const [messageApi, showMessage] = message.useMessage();
  const [notify, showNotification] = notification.useNotification();

  const [seedphrase, setSeedphrase] = useState("");
  const recoveryPhrase = useSelector(
    (state: RootState) => state.account.recoveryPhrase
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleMenuClick = (e: any) => {
    // Handle menu item click
    if (e.key === "3") {
      if (passwrod) {
        dispatch(setIsAuthenticated('true'));
        notify.open({
          message: "Kyber Locked !!!!",
          description:
            '"Kyber Locked!!!" indicates that access to your Khyber crypto wallet is currently restricted or unavailable. This message alerts users that they are unable to access their wallet or perform transactions temporarily. Its essential to be aware of this status to ensure the security of your funds and data.',
          icon: <LockOutlined style={{ color: "red" }} />,
        });
      } else {
        setOpen(true);
      }
    }
    if (e.key === "2") {
      // import or recover
      setIsModalOpen(true);
    }
    if (e.key === "1") {
      (async () => {
        try {
          const result = await generateAccount();
          const encryptedSeedPhrase = encrypt(result.seedPhrase);
          dispatch(setAccount(result.account));
          dispatch(setEncryptedRecoveryPhrase(encryptedSeedPhrase));
          messageApi.open({
            type: "success",
            content: "Account created",
          });
          dispatch(setRecoveryPhrase("")); // Clear recovery phrase on account creation
        } catch (error) {
          console.error("Error creating account:", error);
        }
      })();
    }
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSeedphrase(event.target.value);
  }

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    console.log("in keydown", seedphrase);

    if (event.keyCode === 13) {
      event.preventDefault();
      recoverAccount(seedphrase);
    }
  };

  const recoverAccount = useCallback(
    async (recoveryPhrase: string) => {
      try {
        const result = await generateAccount(recoveryPhrase);
        dispatch(setAccount(result.account));
        dispatch(setRecoveryPhrase(recoveryPhrase));

        messageApi.open({
          type: "success",
          content: "Account Imported",
        });
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error recovering account:", error);
        // Handle the error, perhaps display an error message to the user
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (recoveryPhrase) {
      setSeedphrase(recoveryPhrase);
      recoverAccount(recoveryPhrase);
    }
  }, [recoveryPhrase, recoverAccount]);

  const [open, setOpen] = useState(false);

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancelPassword = () => {
    setOpen(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick} className="setting-ul-bg">
      <Menu.Item key="1" className="custom-li">
        <PlusOutlined
          style={{ marginRight: "5px", fontSize: "15px", color: "#bda361" }}
        />
        Add Account
      </Menu.Item>
      <Menu.Item key="2" className="custom-li">
        <ImportOutlined
          style={{ marginRight: "5px", fontSize: "15px", color: "#bda361" }}
        />
        import existing
      </Menu.Item>
      <Menu.Item key="3" className="custom-li">
        <LockOutlined
          style={{ marginRight: "5px", fontSize: "15px", color: "#bda361" }}
        />
        {passwrod ? "Lock Kyber" : "Set Password"}
      </Menu.Item>
    </Menu>
  );

  const onFinish = (values: any) => {
    dispatch(setPassword(values.password));
    setOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {showMessage}
      {showNotification}
      <Dropdown overlay={menu} trigger={["click"]}>
        <span className="pointer">
          <MoreOutlined style={{ fontSize: "30px" }} className="pointer" />
        </span>
      </Dropdown>

      <Modal
        title="Import  Existing Accounts"
        className="antd-headerModel"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        style={{ width: "100%" }}
      >
        <form onSubmit={(event) => event.preventDefault()}>
          <Input
            placeholder="Seedphrase or private key for recovery"
            value={seedphrase}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </form>
      </Modal>

      <Modal
        open={open}
        title="Set Password"
        onOk={handleOk}
        onCancel={handleCancelPassword}
        footer={null}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }} // Set the maximum width to 600 pixels
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AccountAction;
