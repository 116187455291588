import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = 'your-secret-key'; // Replace with a strong secret key
const IV_LENGTH = 16;

export const encrypt = (text: string): string => {
  const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);
  const cipherText = CryptoJS.AES.encrypt(text, ENCRYPTION_KEY, { iv });
  return `${iv.toString(CryptoJS.enc.Hex)}:${cipherText.toString()}`;
};

export const decrypt = (text: string): string => {
  const [ivHex, cipherText] = text.split(':');
  const iv = CryptoJS.enc.Hex.parse(ivHex);
  const decryptedText = CryptoJS.AES.decrypt(cipherText, ENCRYPTION_KEY, { iv });
  return decryptedText.toString(CryptoJS.enc.Utf8);
};
