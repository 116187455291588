// reducers/accountReducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from '../models/Account';

interface AccountState {
  recoveryPhrase: string | null;
  encryptedRecoveryPhrase: string | null;
  account: Account | null;
  password: string | null; 
  isAuthenticated: string;
}

const initialState: AccountState = {
  recoveryPhrase: null,
  encryptedRecoveryPhrase: null,
  account: null,
  password: null,
  isAuthenticated: 'false',
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setRecoveryPhrase: (state, action: PayloadAction<string>) => {
      state.recoveryPhrase = action.payload;
    },
    setEncryptedRecoveryPhrase: (state, action: PayloadAction<string | null>) => {
      state.encryptedRecoveryPhrase = action.payload;
    },
    setAccount: (state, action: PayloadAction<Account | null>) => {
      state.account = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = action.payload; // Update authentication state
    },
  },
});

export const { setRecoveryPhrase, setEncryptedRecoveryPhrase, setAccount, setPassword, setIsAuthenticated } = accountSlice.actions;
export default accountSlice.reducer;

