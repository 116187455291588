import React from "react";
import NFTImg from "../assets/img/no-nfts.svg";

const NFTs: React.FC = () => {
  return (
    <>
      <img src={NFTImg} alt="No NFTs" />
      <p className="no-nft">No NFTs yet</p>
    </>
  );
};

export default NFTs;
