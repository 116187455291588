import React, { useCallback, useEffect, useState } from "react";
import { Wallet, utils } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import {
  setRecoveryPhrase,
  setAccount,
  setEncryptedRecoveryPhrase,
} from "../../reducers/accountReducer";
import { RootState } from "../../store";
import { generateAccount } from "../../utils/AccountUtils";
import { encrypt, decrypt } from "../../utils/cryptoUtils";
import AccountDetail from "./AccountDetail";
import Headers from "../../components/Header";
const { HDNode } = utils;

function AccountCreate() {
  const [seedphrase, setSeedphrase] = useState("");
  const dispatch = useDispatch();
  const recoveryPhrase = useSelector(
    (state: RootState) => state.account.recoveryPhrase
  );
  const account = useSelector((state: RootState) => state.account.account);
  const encPhrase = useSelector(
    (state: RootState) => state.account.encryptedRecoveryPhrase
  );
  const [showRecoverInput, setShowRecoverInput] = useState(false);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSeedphrase(event.target.value);
  }

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      recoverAccount(seedphrase);
    }
  };

  const recoverAccount = useCallback(
    async (recoveryPhrase: string) => {
      try {
        const result = await generateAccount(recoveryPhrase);
        dispatch(setAccount(result.account));
        dispatch(setRecoveryPhrase(recoveryPhrase));
      } catch (error) {
        console.error("Error recovering account:", error);
        // Handle the error, perhaps display an error message to the user
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (recoveryPhrase) {
      setSeedphrase(recoveryPhrase);
      recoverAccount(recoveryPhrase);
    }
  }, [recoveryPhrase, recoverAccount]);

  const createAccount = async () => {
    try {
      const result = await generateAccount();
      const encryptedSeedPhrase = encrypt(result.seedPhrase);
      dispatch(setAccount(result.account));
      dispatch(setEncryptedRecoveryPhrase(encryptedSeedPhrase));
      dispatch(setRecoveryPhrase("")); // Clear recovery phrase on account creation
    } catch (error) {
      console.error("Error creating account:", error);
      // Handle the error, perhaps display an error message to the user
    }
  };

  const additionalAccount = async () => {
    // encPhrase
    console.log("enc phrase", encPhrase);

    if (encPhrase) {
      const seedPhrase = decrypt(encPhrase);
      let index = 7;
      const rootNode = HDNode.fromMnemonic(seedPhrase);
      const childNode = rootNode.derivePath(`m/44'/60'/0'/0/${index}`);
      const wallet = new Wallet(childNode.privateKey);
      const privateKey = wallet._signingKey().privateKey;

      console.log("the new wallet of the master seedpharse", wallet);
      console.log("the privateKey", privateKey);
    }
  };

  useEffect(() => {
    
    if (encPhrase) {
      const seedPhrase = decrypt(encPhrase);
      const generateHDWallet = (seedPhrase: string) => {
        const rootNode = HDNode.fromMnemonic(seedPhrase);
      };
      generateHDWallet(seedPhrase)
    }
  }, []);

  

  return (
    <div className="AccountCreate p-5 m-3 card shadow">
      <Headers account={account ? account.address : '0x0000000000000000000000000000000000000000'}  />
     
      {account && (
        <>
          <hr />
          <AccountDetail account={account} />
        </>
      )}
    </div>
  );
}

export default AccountCreate;
