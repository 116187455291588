import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';

interface BodyProps {
  tab1: any;
  tab2: any;
  tab3: any;
}

const onChange = (key: string) => {
  console.log(key);
};

const Body: React.FC<BodyProps> = ({ tab1, tab2, tab3 }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: "Tokens",
      children: tab1,
    },
    {
      key: '2',
      label: "NFTs",
      children: tab2,
    },
    {
      key: '3',
      label: "Activity",
      children: tab3,
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
};

export default Body;
