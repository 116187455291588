import AccountCreate from "./scenes/Account/AccountCreate";
import logo from "../src/assets/img/sc-offical.png";
import "./App.scss";
import LockScreen from "./components/lock.screen";
import { useSelector } from "react-redux";
import { RootState } from "./store";

function App() {
  const auth = useSelector((state: RootState) => state.account.isAuthenticated);
  return (
    <div className="App container">
      {auth === "true" ? (
        <LockScreen />
      ) : (
        <>
          <div className="kyber-logo">
            <img src={logo} alt="logo" />
            <h1>KYBER</h1>
          </div>
          <AccountCreate />
        </>
      )}
    </div>
  );
}

export default App;
